import React, { useState, useEffect } from 'react'
import * as web3 from '@solana/web3.js'
import './buyTable.sass'
import { StaticImage } from 'gatsby-plugin-image'
import { ThreeDots } from 'react-loader-spinner'
import { useLocation } from '@reach/router'
import { useWallet } from '@solana/wallet-adapter-react'
import * as buffer from 'buffer'
import axios from 'axios'
import CountdownTimer from '../ultities/countdownTimer'
import Popup from 'reactjs-popup'
import Wallet from '../wallet/wallet'

const arrowDown = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.76001 8.87988L12.12 15.2399L18.48 8.87988" stroke="black" strokeWidth="2" strokeLinecap="round"/>
</svg>
const arrowUp = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.24 15.1201L11.88 8.76012L5.51999 15.1201" stroke="black" strokeWidth="2" strokeLinecap="round"/>
</svg>

const notice = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="20" y="20" width="20" height="20" rx="10" transform="rotate(180 20 20)" fill="#FCECAE"/>
<path d="M8.64 13.96C8.64 13.5973 8.768 13.288 9.024 13.032C9.28 12.7653 9.57867 12.632 9.92 12.632C10.2613 12.632 10.5173 12.728 10.688 12.92C10.8693 13.1013 10.96 13.3733 10.96 13.736C10.96 14.0987 10.8373 14.4187 10.592 14.696C10.3467 14.9627 10.0533 15.096 9.712 15.096C9.38133 15.096 9.12 14.9947 8.928 14.792C8.736 14.5893 8.64 14.312 8.64 13.96ZM11.632 3.8L10.736 10.888L9.216 11.112V4.2L11.632 3.8Z" fill="#E42929"/>
</svg>

const tokenLogo = <StaticImage
src="../../images/godlen-icon.png"
height={20}
className="iconImage"
layout="fixed"
alt="Godlen logo"
/>

const spinner = <ThreeDots
visible={true}
height="16"
width="40"
color="#000"
radius="9"
ariaLabel="three-dots-loading"
wrapperStyle={{}}
wrapperClass=""
/>

const solLogo = <StaticImage
src="../../images/sol-logo.png"
height={20}
className="iconImage"
layout="fixed"
alt="SOL logo"
/>

const BuyTable = () => {
  const receiverPublicKey = 'VersgBc42RbaBKQddDmz3jJA6Ts8Jk7MhNe7TyAqdLp'
  const [open, setOpen] = useState(false)
  const [openTab, setOpenTab] = useState(false)
  const [token1, setToken1] = useState(0)
  const [loading, setLoading] = useState(false)
  const [token2, setToken2] = useState(0)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const referralLink = queryParams.get('referral')
  const [referralCode, setreferralCode] = useState(referralLink || '')
  const [apply, setApply] = useState(!!referralLink)
  const [error, setError] = useState(false)
  const wallet = useWallet()

  console.log('Got referral: ', referralLink)

  const closeModal = () => setOpen(false)

  const presaleData = [
    { date: '2024 april 10', price: 33333 },
    { date: '2024 april 11', price: 32000 },
    { date: '2024 april 12', price: 31000 },
    { date: '2024 april 13', price: 30000 },
    { date: '2024 april 14', price: 29000 },
    { date: '2024 april 15', price: 28000 },
    { date: '2024 april 16', price: 27000 },
    { date: '2024 april 17', price: 26000 },
    { date: '2024 april 18', price: 25000 },
    { date: '2024 april 19', price: 24000 },
    { date: '2024 april 20', price: 23000 },
    { date: '2024 april 21', price: 22000 },
    { date: '2024 april 22', price: 21000 },
    { date: '2024 april 23', price: 20000 },
    { date: '2024 april 24', price: 19000 },
    { date: '2024 april 25', price: 18000 },
    { date: '2024 april 26', price: 17000 },
    { date: '2024 april 27', price: 16000 },
    { date: '2024 april 28', price: 15000 },
    { date: '2024 april 29', price: 14000 },
    { date: '2024 april 30', price: 13000 },
    { date: '2024 may 1', price: 12000 },
    { date: '2024 may 2', price: 11000 },
    { date: '2024 may 3', price: 10000 },
    { date: '2024 may 4', price: 10000 },
    { date: '2024 may 5', price: 10000 },
    { date: '2024 may 6', price: 10000 },
    { date: '2024 may 7', price: 10000 },
    { date: '2024 may 8', price: 10000 },
    { date: '2024 may 9', price: 10000 },
    { date: '2024 may 10', price: 10000 },
    { date: '2024 may 11', price: 10000 },
    { date: '2024 may 12', price: 10000 },
    { date: '2024 may 13', price: 10000 },
    { date: '2024 may 14', price: 10000 },
    { date: '2024 may 15', price: 10000 },
    { date: '2024 may 16', price: 10000 },
    { date: '2024 may 17', price: 10000 },
    { date: '2024 may 18', price: 10000 },
    { date: '2024 may 19', price: 10000 },
    { date: '2024 may 20', price: 10000 },
    { date: '2024 may 21', price: 10000 },
    { date: '2024 may 22', price: 10000 }
  ]

  function formatDate (date) {
    const monthNames = ['january', 'february', 'march', 'april', 'may', 'june',
      'july', 'august', 'september', 'october', 'november', 'december']
    const year = date.getUTCFullYear()
    const month = monthNames[date.getUTCMonth()]
    const day = date.getUTCDate()
    return `${year} ${month} ${day}`
  }

  function todaysPrice (today, presaleData) {
    const formattedToday = formatDate(today)

    const matchingEntry = presaleData.find(entry => entry.date === formattedToday)
    if (matchingEntry) {
      return matchingEntry.price
    } else {
      return 0
    }
  }

  // const today = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))

  // const price = todaysPrice(today, presaleData)
  const price = 8000
  // console.log('Today: ', price)

  // const solRate = 1 / price
  const solRate = 1 / price

  function handleChange (e) {
    if (e.target.value) {
      const replaceIfComma = e.target.value.replace(/,/g, '.')
      setToken1(replaceIfComma)
    } else {
      setToken1(e.target.value)
    }
    setToken2((e.target.value / solRate).toFixed(0))
  }

  const handleWallet = async () => {
    try {
      await wallet.connect()
    } catch (error) {
      setOpen(o => !o)
      console.error('Error connecting wallet:', error)
      // Handle error
    }
  }

  async function sendSol (receiverPublicKey) {
    // await wallet.connect(() => {})
    console.log('SOL')
    setLoading(true)
    console.log('Get: ', wallet.publicKey)

    if (wallet.publicKey) {
      if (token1 < 0.0001) {
        setLoading(false)
        setToken1(0)
        setToken2(0)
        return alert('Minimum allowed transaction 0.1 SOL')
      }

      // const clusterApiUrl = 'https://api.devnet.solana.com' // Devnet cluster URL
      // const clusterApiUrl = 'https://api.mainnet-beta.solana.com'
      const clusterApiUrl = 'https://solana-mainnet.core.chainstack.com/3ceabc96bda2f5b518ad020771e614ba' // Mainet cluster URL

      const connection = new web3.Connection(clusterApiUrl)
      console.log(connection)
      const walletAccountInfo = await connection.getAccountInfo(wallet.publicKey)
      if (!walletAccountInfo) {
        handleWallet()
      }
      const receiverPublicKeyInstance = new web3.PublicKey(receiverPublicKey)
      const transaction = new web3.Transaction().add(
        web3.SystemProgram.transfer({
          fromPubkey: wallet.publicKey,
          toPubkey: receiverPublicKeyInstance,
          lamports: web3.LAMPORTS_PER_SOL * token1
        })
      )
      // transaction.feePayer = wallet

      // transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash

      try {
        // const signature = await window.solana.signAndSendTransaction(transaction)
        const latestBlockhash = await connection.getLatestBlockhash()
        transaction.feePayer = wallet.publicKey
        transaction.recentBlockhash = latestBlockhash.blockhash
        const signature = await wallet.sendTransaction(transaction, connection)
        console.log('signature: ', signature)
        handleTransactionSending(signature)
        setLoading(false)
        setToken1(0)
        setToken2(0)
        alert('Transaction completed.')
        // const confirmed = await connection.confirmTransaction(signature, 'processed')
        // console.log('Signature from adapter: ', confirmed)
        // // Send the transaction
      } catch (e) {
        setToken1(0)
        setToken2(0)
        setLoading(false)
        console.log('Did not received signature', e)
        alert(e)
      }
    } else {
      setLoading(false)
      setToken1(0)
      setToken2(0)
      handleWallet()
    }
  }
  const handleOpenTab = () => {
    setOpenTab((o) => !o)
  }

  const handleApply = () => {
    console.log('getting code..')
    handleCodeCheck()
  }

  const handleTransactionSending = (signature) => {
    const data = JSON.stringify({
      address: wallet.publicKey,
      amount: token1,
      transaction_id: signature,
      referral_code: referralCode
    })

    const config = {
      method: 'post',
      url: 'https://api.godlenfish.com/tokensale/purchase/',
      headers: {
        'Content-Type': 'application/json'
      },
      data
    }
    console.log(data)
    axios.request(config)
      .then((response) => {
        console.log('Code: ', response)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleCodeCheck = () => {
    const config = {
      method: 'get',
      url: `https://api.godlenfish.com/referral/code/${referralCode}`,
      headers: {
        'Content-Type': 'application/json'
      }
    }
    axios.request(config)
      .then((response) => {
        console.log(response)
        if (response.data.code) {
          setError(false)
          setApply(true)
          console.log('good')
        } else {
          setError(true)
          setTimeout(() => {
            setError(false)
          }, 20000)
        }
      })
      .catch((error) => {
        console.log(error)
        setError(true)
        setTimeout(() => {
          setError(false)
        }, 20000)
      })
  }

  const handleRemove = () => {
    setreferralCode('')
    setApply(false)
    setError(false)
  }

  const targetTime = new Date('2024-05-29T13:59:59Z').toISOString()

  useEffect(() => {
    if (referralCode) {
      localStorage.setItem('referral', referralCode)
    }
    const referralMemory = localStorage.getItem('referral')
    if (referralMemory) {
      const referralCodeString = referralMemory.toString()
      setreferralCode(referralCodeString)
      setApply(true)
    } else {
      setApply(false)
    }
  }, [])

  return (
    <div className="presaleContainerRight">
      <div className="presaleRate">
        <span>1 $SOL {solLogo}</span>
        <span className='heroEquals'>=</span>
        <span>{price} $GODLEN {tokenLogo}</span>
      </div>
      <h3>Listing on Raydium & Godlen distribution in:</h3>
      <div className="presaleCounter"><CountdownTimer targetTime={targetTime} /></div>
      <div className='buyInputsContainer'>
        <div>
          <div className='labelContainer'><span>Pay with SOL</span></div>
          <div className='inputWrap'>
            <input onFocus={handleWallet} onChange={handleChange} type="text" value={token1} />
            {solLogo}
          </div>
        </div>
        <div>
          <div className='labelContainer'><span>Receive $GODLEN</span></div>
          <div className='inputWrap'>
            <input type="text" value={token2} />
            {tokenLogo}
          </div>
        </div>
      </div>
      <p>Min 0.1 SOL - Max 20 SOL per wallet</p>
      <div className='referralsContainer'>
        <button onClick={() => handleOpenTab()}><span>Enter referral code (optional)</span>{openTab ? arrowUp : arrowDown}</button>
        { openTab
          ? <div className='applyContainer'>
          <div className='inputWrap'>
            <input onChange={(e) => setreferralCode(e.target.value)} type="text" placeholder="Code" value={referralCode} />
            { referralCode !== '' ? <button onClick={() => { handleRemove() }} className='removeButton'>Remove</button> : null }
          </div>
          <button onClick={() => handleApply()} style={{ background: apply ? '#3D8031' : '#000' }}>{ apply ? 'Applied' : 'Apply' }</button>
        </div>
          : null }
        {error ? <div className='noticeContainer'>{notice}<span>Code not found</span></div> : null}
      </div>
      <button onClick={() => { sendSol(receiverPublicKey) }}>BUY NOW {loading ? spinner : null}</button>
      <Popup className="modalWrapper" open={open} closeOnDocumentClick onClose={closeModal}>
        <div className="modal">
          <a className="close" onClick={closeModal}>
            &times;
          </a>
          <Wallet closeModal={closeModal} />
        </div>
      </Popup>
    </div>
  )
}

export default BuyTable
