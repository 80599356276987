import React, { useState } from 'react'
import './presale.sass'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { StaticImage } from 'gatsby-plugin-image'

import BuyTable from '../../components/buyTable/BuyTable'

const copyButton = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.0177 3.33398H8.35114C7.91373 3.33398 7.49416 3.50923 7.18483 3.82115C6.87551 4.13308 6.70172 4.55622 6.70172 4.99742V6.61761H4.98788C4.55109 6.61641 4.13178 6.79 3.82173 7.10028C3.51166 7.4104 3.33636 7.83203 3.33398 8.27234V15.0039C3.33518 15.4447 3.50927 15.8672 3.81844 16.1788C4.12746 16.4906 4.54646 16.6661 4.98356 16.6673H11.6502C12.0876 16.6673 12.5071 16.4921 12.8165 16.1802C13.1258 15.8682 13.2996 15.4451 13.2996 15.0039V13.3837H15.0134C15.4502 13.3849 15.8695 13.2113 16.1796 12.901C16.4896 12.5909 16.6649 12.1693 16.6673 11.729V4.99742C16.6661 4.55665 16.492 4.13412 16.1829 3.8225C15.8738 3.51073 15.4548 3.33517 15.0177 3.33398ZM11.9801 14.9994L11.98 14.9996C11.98 15.1833 11.8323 15.3321 11.6502 15.3321H4.98356C4.80229 15.3299 4.656 15.1822 4.65361 14.9996V8.27226C4.65361 8.08857 4.8014 7.93954 4.98356 7.93954H11.6502C11.738 7.93848 11.8226 7.97314 11.8846 8.03583C11.9468 8.09836 11.9812 8.18365 11.98 8.27226V15.0038L11.9801 14.9994ZM15.3477 11.7289C15.3477 11.9126 15.1999 12.0616 15.0177 12.0616H13.3039V8.27237C13.3039 7.8313 13.1301 7.40819 12.8208 7.09627C12.5115 6.78419 12.0919 6.60893 11.6543 6.60893H8.02119V4.99749C8.02119 4.81381 8.16883 4.66477 8.35099 4.66477H15.0176C15.1989 4.66719 15.3451 4.81471 15.3475 4.99749L15.3477 11.7289Z" fill="black"></path></svg>

const Presale = () => {
  const presaleData = [
    { date: '2024 april 10', price: 33333 },
    { date: '2024 april 11', price: 32000 },
    { date: '2024 april 12', price: 31000 },
    { date: '2024 april 13', price: 30000 },
    { date: '2024 april 14', price: 29000 },
    { date: '2024 april 15', price: 28000 },
    { date: '2024 april 16', price: 27000 },
    { date: '2024 april 17', price: 26000 },
    { date: '2024 april 18', price: 25000 },
    { date: '2024 april 19', price: 24000 },
    { date: '2024 april 20', price: 23000 },
    { date: '2024 april 21', price: 22000 },
    { date: '2024 april 22', price: 21000 },
    { date: '2024 april 23', price: 20000 },
    { date: '2024 april 24', price: 19000 },
    { date: '2024 april 25', price: 18000 },
    { date: '2024 april 26', price: 17000 },
    { date: '2024 april 27', price: 16000 },
    { date: '2024 april 28', price: 15000 },
    { date: '2024 april 29', price: 14000 },
    { date: '2024 april 30', price: 13000 },
    { date: '2024 may 1', price: 12000 },
    { date: '2024 may 2', price: 11000 },
    { date: '2024 may 3', price: 10000 },
    { date: '2024 may 4', price: 10000 },
    { date: '2024 may 5', price: 10000 },
    { date: '2024 may 6', price: 10000 },
    { date: '2024 may 7', price: 10000 },
    { date: '2024 may 8', price: 10000 },
    { date: '2024 may 9', price: 10000 },
    { date: '2024 may 10', price: 10000 },
    { date: '2024 may 11', price: 10000 },
    { date: '2024 may 12', price: 10000 },
    { date: '2024 may 13', price: 10000 },
    { date: '2024 may 14', price: 10000 },
    { date: '2024 may 15', price: 10000 },
    { date: '2024 may 16', price: 10000 },
    { date: '2024 may 17', price: 10000 },
    { date: '2024 may 18', price: 10000 },
    { date: '2024 may 19', price: 10000 },
    { date: '2024 may 20', price: 10000 },
    { date: '2024 may 21', price: 10000 },
    { date: '2024 may 22', price: 10000 },
    { date: '2024 may 23', price: 'Heating up' },
    { date: '2024 may 24', price: 'Heating up' },
    { date: '2024 may 25', price: 'Heating up' },
    { date: '2024 may 26', price: 'Heating up' },
    { date: '2024 may 27', price: 'Heating up' },
    { date: '2024 may 28', price: 'Heating up' },
    { date: '2024 may 29', price: 'Launch' }
  ]

  function formatDate (date) {
    const monthNames = ['january', 'february', 'march', 'april', 'may', 'june',
      'july', 'august', 'september', 'october', 'november', 'december']
    const year = date.getUTCFullYear()
    const month = monthNames[date.getUTCMonth()]
    const day = date.getUTCDate()
    return `${year} ${month} ${day}`
  }

  function todaysPrice (today, presaleData) {
    const formattedToday = formatDate(today)

    const matchingEntry = presaleData.find(entry => entry.date === formattedToday)
    if (matchingEntry) {
      return matchingEntry.price
    } else {
      return 0
    }
  }

  const today = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))

  const price = todaysPrice(today, presaleData)
  console.log('Today: ', price)

  function isDatePassed (dateString) {
    const date = new Date(dateString)
    // const todayUTC = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()))
    const yesterdayUTC = new Date('2024 may 4')
    yesterdayUTC.setUTCDate(yesterdayUTC.getUTCDate() - 1)
    // Compare dates without time
    return date < yesterdayUTC
  }

  const [copied, setcopied] = useState(0)

  const handleCopy = (state) => {
    setcopied(state)
    setTimeout(() => {
      setcopied(false)
    }, 1000)
  }

  return (
    <div id="presale" className="presaleSection">
      <div className="presaleInnerWrap">
        <div className="presaleRow">
          <div className="presaleBlocksContainer1">
            <div className="presaleContainerLeft">
              <div className='presaleTextWrapper'>
                <h2>$GODLEN FISH</h2>
                <p>Swim in the fortune with the ruler of luck on $SOL </p>
              </div>
              <div className="presaleAddresses">
                <div>
                  <p>You can directly send $SOL to our address:</p>
                  <div className="addressContainer"><span>VersgBc42RbaBKQddDmz3jJA6Ts8Jk7MhNe7TyAqdLp</span><CopyToClipboard onCopy={() => { handleCopy(1) }} className="copyButton" text="VersgBc42RbaBKQddDmz3jJA6Ts8Jk7MhNe7TyAqdLp">{copyButton}</CopyToClipboard>{copied === 1 ? <span className="copiedBorad">Copied to clipboard</span> : null}</div>
                </div>
                <div>
                  <p>* If Sol network is busy, send $ETH to our BASE or Ethereum:</p>
                  <div className="addressContainer"><span>0xB0b6715F24bd84Dd945205426AE6643b33488A51</span><CopyToClipboard onCopy={() => { handleCopy(2) }} className="copyButton" text="0xB0b6715F24bd84Dd945205426AE6643b33488A51">{copyButton}</CopyToClipboard>{copied === 2 ? <span className="copiedBorad">Copied to clipboard</span> : null}</div>
                </div>
                <p>20 SOL contributed = Godlenfish NFT. Limited to the first 500 wallets.</p>
                <p className='auditWrapper'>Checkout our token got audit: <a className='tokenAudit' target='_blank' href="https://coinsult.net/projects/godlenfish/" rel="noreferrer">Token Audit Report</a></p>
              </div>
            </div>
            <BuyTable price={price} />
          </div>
          <div className="presaleBlocksContainer2">
            <h3>$GODLEN price by day</h3>
            <div className="priceChangeTable">
              <div>
                <span>Day</span>
                <ul>
                  {presaleData.map((data, index) => (
                    <li key={index} className={isDatePassed(data.date) ? 'passedDate' : ''}>{data.date}</li>
                  ))}
                </ul>
              </div>
              <div>
                <span>$GODLEN for 1 $SOL</span>
                <ul>
                  {presaleData.map((data, index) => (
                      <li key={index} className={isDatePassed(data.date) ? 'passedDate' : ''}>{data.price}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="presaleInnerWrap">
        <div className='gotFishContainer'>
            <StaticImage
                  src="../../images/got-fish.jpg"
                  className="presaleBannerFish"
                  placeholder="blurred"
                  height={445}
                  layout="constrained"
                  alt="The Godlenfish logo"
            />
            <div className='presaleBannerTextContainer'>
              <h2>GOTFISH?</h2>
            </div>
        </div>
      </div>
      <div className="presaleInnerWrap">
        <h2>FAQ</h2>
        <div className='faqContainer'>
          <div className="gotFishContainerLeft">
            <div className="innerFaq">
              <span>HOW TO BUY WITH SOL</span>
              <ul>
                <li>Press 'Buy Now' above and connect wallet</li>
                <li>Enter amount of SOL to swap for $GODLEN</li>
                <li>Hit 'Buy Now' again and confirm transaction</li>
                <li>If you cant connect your wallet, just send SOL to <span>VersgBc42RbaBKQddDmz3jJA6Ts8Jk7MhNe7TyAqdLp</span> and tokens will be airdropped to the sending wallet.</li>
              </ul>
              <p>NB: Dont use a CEX to send SOL! Use your own wallet</p>
            </div>
            <div className="innerFaq">
              <span>HOW TO BUY WITH ETH</span>
              <p>Send ETH on Ethereum or Base network. Godlen amount will be calculated and airdropped in the same days with SOL users on the Base network. Send to
              <span>0xB0b6715F24bd84Dd945205426AE6643b33488A51</span> and tokens will be airdropped to the sending wallet.</p>
            </div>
          </div>
          <div className="gotFishContainerRight">
            <div className="innerFaq">
              <p>Keep an eye of Godlen arrival to your wallet. Follow our twitter for further announcements regarding airdrop and listing.</p>
            </div>
            <div className="innerFaq">
              <p>Godlen tokens will be airdropped to your SOL or Base wallet 29th of May, around listing time in random manner. Listing is planned for the 29th of May. </p>
            </div>
            <div className="innerFaq">
              <p>If you need any help, feel free to drop us an message to official godlenmail: info@godlenfish.com.</p>
            </div>
            <div className="innerFaq">
              <p>Airdrops or other marketing tokens will be distributed until 22 of May.</p>
            </div>
            <div className="innerFaq">
              <p>$GODLEN have legal token audit. You can check it on this link <a target='_blank' href="https://coinsult.net/projects/godlenfish/" rel="noreferrer">Token Audit Report</a>.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Presale
