import React, { useState, useEffect } from 'react'
import './countdownTimer.sass'

const CountdownTimer = ({ targetTime }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetTime) - +new Date()
    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      }
    }
    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const initializeTimer = () => {
      setIsLoading(true)
      const calculatedTimeLeft = calculateTimeLeft()
      setTimeLeft(calculatedTimeLeft)
      setIsLoading(false)
    }

    initializeTimer()

    const timer = setInterval(() => {
      initializeTimer()
    }, 1000)

    return () => clearInterval(timer)
  }, [targetTime])

  const formatTime = value => (value < 10 ? `0${value}` : value)

  const timerComponents = isLoading
    ? ['xx', 'xx', 'xx', 'xx'].map((value, index) => (
        <div className='timeWrapper' key={index}>
          <div className='timeUnitWrapper'>
            <span className='timeNumbers'>{value}</span>
            <span className='timeText'>
              {index === 0 ? 'days' : index === 1 ? 'hours' : index === 2 ? 'minutes' : 'seconds'}
            </span>
          </div>
          <span className='timeDots'>{index === 3 ? '' : ':'}</span>
        </div>
      ))
    : Object.keys(timeLeft).map(interval => (
        <div className='timeWrapper' key={interval}>
          <div className='timeUnitWrapper'>
            <span className='timeNumbers'>{formatTime(timeLeft[interval])}</span>
            <span className='timeText'>{interval}</span>
          </div>
          <span className='timeDots'>{interval === 'seconds' ? '' : ':'}</span>
        </div>
    ))

  return <div className='timeContainer'>{timerComponents}</div>
}

export default CountdownTimer
